// import firebase from "firebase/app";
// import "firebase/auth";
// import {
//   FirebaseAuthProvider,
//   FirebaseAuthConsumer,
//   IfFirebaseAuthed,
//   IfFirebaseAuthedAnd
// } from "@react-firebase/auth";
// import { firebaseConfig } from './firebaseConfig'

import Header from './components/Header'
import './styles/styles.scss'

const App = () => {
  return (
    <div className="content">
      <div className="container">
        <div className="perfect-center">
          <img className="coming-soon--image" src="assets/static/images/logo.svg"/>
        </div>
      </div>
    </div>
  )
}
export default App
