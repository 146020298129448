import PropTypes from 'prop-types'

const Button = ({ color, text, onClick }) => {
  const buttonColor = () => {
    if (color) {
      return ' btn--' + color
    }
  }

  return (
    <button
      className={'btn' + buttonColor()}
      onClick={onClick}>
      {text}
    </button>
  )
}

Button.defaultProps = {
  color: 'green',
  text: 'Button'
}

Button.propTypes = {
  color: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func
}

export default Button
